import { css } from 'astroturf';
import React from 'react';
import { ArrowLeft, ArrowRight } from 'react-bytesize-icons';
import SEO from '../components/SEO';
import Layout from '../layout';

const styles = css`
  .container {
    position: relative;
    width: 100%;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h1 {
      font-weight: 700;
      font-size: 6.3vw;
      line-height: 1;
      margin-top: 0;
      margin-bottom: 0;
      @media (min-width: 1024px) {
        font-size: 64px;
      }
    }

    & p {
      padding: 0.5rem 1rem;
      text-align: center;
    }

    & > svg {
      width: min(500px, 100%);
    }
  }
  .buttonbar {
    display: flex;
    gap: 2rem;
    justify-content: center;
    flex-direction: column;
    @media (min-width: 400px) {
      flex-direction: row;
    }
  }
  .button {
    position: relative;
    pointer-events: all;
    margin: 0rem 0 0;
    font-size: 1rem;
    font-weight: 400;
    color: #107db5;
    line-height: 1.25;
    padding: 0.33em 0;
    border-bottom: 1px solid #000;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    border-radius: 0;
    background: none;
    border:none;

    &::after {
      position: absolute;
      right: 0px;
      bottom: -1px;
      content: ' ';
      width: 100%;
      height: 1px;
      background: #107db5;
      transition: 0.25s ease-out width;
    }

    & svg {
      transform: translateX(0);
      transition: 0.2s 0.12s ease-out;
    }

    &:hocus {
      color: #000;
      text-decoration: none;

      & svg {
        transform: translateX(4px);
      }

      &::after {
        width: 0;
      }
    }

    &:first-child:hocus {
      & svg {
        transform: translateX(-4px);
      }
    }

    & svg {
      vertical-align: bottom;
    }
  }

  .astronaut {
    animation-name: hovering;
    animation-duration: 8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  .shadow {
    transform-origin: 50% 75%;
    animation-name: shadow;
    animation-duration: 8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  @keyframes hovering {
    0%,
    100% {
      transform: translateY(-6px);
    }
    50% {
      transform: translateY(6px);
    }
  }

  @keyframes shadow {
    50% {
      transform: scale(0.95);
      opacity: 0.75;
    }
    0%,
    100% {
      transform: scale(1.1);
      opacity: 0.25;
    }
  }
`;

const PageNotFound = () => (
  <Layout>
    <SEO title="404 page not found" keywords={['']} />
    <div className={styles.container}>
      <Astronaut />
      <h1>Page not found</h1>
      <p>We can't find what you were looking for, but the links below might help.</p>
      <div className={styles.buttonbar}>
        <button className={styles.button} onClick={() => history.back()}>
          <ArrowLeft height={24} /> Go Back
        </button>
        <a className={styles.button} href="https://dashboard.polypane.app/register/">
          Try Polypane for free <ArrowRight height={24} />
        </a>
      </div>
    </div>
  </Layout>
);

export default PageNotFound;

const Astronaut = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 300">
    <defs>
      <linearGradient id="a" x1="247.23" x2="218.59" y1="119.33" y2="119.88" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#fff" />
        <stop offset=".03" stopColor="#f7931e" />
        <stop offset="1" stopColor="#1b1464" />
      </linearGradient>
    </defs>
    <path
      fill="#133cb6"
      d="m 48.78,203.85 v -97.74 c -0.0012,-3.57327 1.9464,-6.862783 5.08,-8.58 L 210.72,11.61 c 2.9739,-1.630726 6.58037,-1.604236 9.53,0.07 L 364.11,93.3 c 3.16487,1.769087 5.08977,5.145358 5,8.77 -0.52,19 -2.23028,82.83379 -2.23028,86.27379"
    />
    <path
      d="m 48.78,203.85 v -97.74 c -0.0012,-3.57327 1.9464,-6.862783 5.08,-8.58 L 210.72,11.61 c 2.9739,-1.630726 6.58037,-1.604236 9.53,0.07 L 364.11,93.3 c 3.16487,1.769087 5.08977,5.145358 5,8.77 -0.52,19 -2.23028,82.83379 -2.23028,86.27379"
      opacity=".61"
    />
    <path
      fill="#b0aaa4"
      d="m53.45 195.47 157.3-89.84a24.51 24.51 0 0 1 24.47.09l127.12 73.85a9.27 9.27 0 0 1 0 16L208 286.28a24 24 0 0 1-24.11.13L53.43 211.56a9.27 9.27 0 0 1 .02-16.09Z"
    />
    <circle cx="287.74" cy="110.79" r="1.55" fill="#fff" />
    <path
      fill="#fff"
      d="M216.34 84.36a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM149 102.13a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM120 149.66a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM165.51 143.46a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM154.76 114.11a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM273.71 116.68v.41a.5.5 0 0 0 1 0v-.41a.5.5 0 0 0-1 0ZM314.71 156.68a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM258.09 95.52a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM274.21 176.11a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM120.46 201.32a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM106.76 146.09l-.41-.41a.5.5 0 0 0-.71.71l.41.41a.5.5 0 0 0 .71-.71ZM87 182.31a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM182.87 239.34a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM211.38 234.8a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM282.06 216.2a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM316.78 190.58a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM267.18 152.55a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM92.77 208.35a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM176.67 81.53c-3.22 0-3.22 5 0 5s3.22-5 0-5Z"
    />
    <ellipse cx="287.74" cy="205.87" fill="#937c68" rx="8.72" ry="3.29" />
    <ellipse cx="123.63" cy="211.64" fill="#937c68" rx="8.72" ry="3.29" />
    <ellipse className={styles.shadow} cx="200.99" cy="211.64" fill="#334c88" rx="27.21" ry="4.53" />
    <g className={styles.astronaut}>
      <path
        fill="#36e476"
        d="M221.27 107.3c-1.33-.14-13.43-5.5-23.58-3.57-2.46.47-20.53 21.89-20 25.37s10.11 15.7 10.11 15.7l25.2-24"
      />
      <path
        d="m210.75 122.4 1.8-1.7 8.3-13.53c-1.32-.14-12.96-5.28-23-3.67.06 7.79 6.18 14.88 12.9 18.9z"
        opacity=".61"
      />
      <path
        fill="#fff"
        d="M247.66 161.21c-1.66-6.78-4.24-18.7-6.28-21.28-3.1-3.92-11.46-12.83-11.46-12.83l-6.12 12.59 8.92 9.16 5.42 14.55c3.43-.73 6.5-.4 9.52-2.19Z"
      />
      <path
        fill="#fff"
        d="M238.72 159.73s-4.09 11.06-2.55 11.95c2.61 1.5 3.15-6.38 4.57-4.73s2.61 9.92 5.12 8.93 4.1-13.19-.55-20.73-6.59 4.58-6.59 4.58ZM166.71 172.69s-5.76-.15-6.75 3-2.31 20 3.49 20.84 6.87-15.45 6.87-15.45M153.11 149.29c-.78-.16-3.34 4.15-3.73 4.8a22.77 22.77 0 0 0-2.57 6.2c-.76 3.1-1.4 7.4-.16 10.45 2.07 5.12 8.26.48 10-2.64a22.89 22.89 0 0 0 1.74-4.4 55 55 0 0 0 .48-6.59V151a39.81 39.81 0 0 0-5.76-1.71ZM199.76 125.07s-11.97 16-13.03 23.2c-1.06 7.19-5.72 34.35-.34 35.66 5.38 1.31 10.84-.17 14-12.42 2.1-8 1.85-20.57 1.85-20.57zM228.2 134.45a76.68 76.68 0 0 0 1.73-7.34l-.24-.35-14.29-9.33s-8.78 3.57-10.33 8.92 4.75 26.94 4.75 26.94l16.52-14a44.78 44.78 0 0 0 1.86-4.84Z"
      />
      <path
        fill="#fff"
        d="m188.06 170.47-21.34 2.22s-4.51 6.84 1.51 12.1c0 0 20.44 2.25 26.38-2.23s-6.55-12.09-6.55-12.09ZM173.54 167.21c-4.88.79-17.23-2.89-17.23-2.89-10-4.08 2.09-13.58 2.09-13.58s9.91 2.14 14.65.63 20.55-17.21 20.55-17.21 1.65 22-.52 25.5-19.54 7.55-19.54 7.55ZM216.8 157.09s9.15-.22 9.15 1.77-3.83 2.6-3.83 2.6 5.28 8.12 2.87 11-8.54 1.84-9.61.61-4.32-9.24-4.32-9.24"
      />
      <path
        fill="#57464e"
        d="M184.33 170.47s6.39 5.74 8.48 6.35 6.24-.65 6.24-.65-3 3.23-6.24 3.38-8.48-9.08-8.48-9.08ZM171.15 168.2a31.9 31.9 0 0 1-2.64-8.61c-.11-2.47 1.34-7.74 1.34-7.74l1.36-.12s-1.62 7.17-1 8.4 3.3 7.08 3.3 7.08.14 2.67-2.36.99Z"
      />
      <path fill="#a8a8a8" d="m226.53 139.5-17.25-1.37s-.1 8.9 1.67 11.5c1.77 2.6 1.68 1.36 1.68 1.36z" />
      <path
        fill="#fff"
        d="M215.39 117.43s-13.39 3.87-16.05 8-2.84 21.75 0 26.4 11.72 12.93 11.72 12.93 5.29-4.57 5.74-6.78c0 0-5.93-9.79-6.64-12.3s5.23-28.25 5.23-28.25Z"
      />
      <path fill="#57464e" d="M199.34 151.78s6.54-3.61 11.54-4.41c0 0-6.38-2.07-11.54 4.41Z" />
      <path
        fill="#fff"
        d="M222.14 127.11s9.74.42 13.6-6.59-1.4-16.34-9.88-17.1-13.28 7-13.28 10.24 2.42 11.62 9.56 13.45Z"
      />
      <path fill="url(#a)" d="M216 118.71s.32 9.64 10.86 9 11.68-9.83 9.92-12.34-14.44-8.37-20.78 3.34Z" />
      <path
        fill="#fff"
        d="M235.48 117.78a.77.77 0 0 0-1.48.41 4.12 4.12 0 0 1-.9 4c-.67.73.42 1.82 1.09 1.09a5.71 5.71 0 0 0 1.29-5.5ZM231.49 123.69a.77.77 0 0 0 0 1.54.77.77 0 0 0 0-1.54Z"
        opacity=".54"
      />
      <path fill="#a8a8a8" d="m215.48 111.68 14.05-17.15s.39-.17.25.26L216 112Z" />
      <path
        fill="#a8a8a8"
        d="M217.79 111.68s-1.56-1.9-2.3-1.83-2.56 2.33-2.23 2.92 1.36 1.68 1.88 1.55 2.65-2.64 2.65-2.64Z"
      />
      <path
        fill="#898989"
        d="M217 110.75c-1 1-2 1.9-3 2.83.42.44.92.81 1.24.74.52-.13 2.66-2.64 2.66-2.64s-.39-.47-.9-.93Z"
      />
      <path fill="#57464e" d="m211.58 151.78-2.48 1.77 1.24 1.52 2.41-1.64-1.17-1.65z" />
      <path
        fill="#777"
        d="M213.34 155.46a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1ZM214.86 157.14a.5.5 0 0 0 0 1 .5.5 0 0 0 0-1Z"
      />
      <path
        fill="#57464e"
        d="M161.7 150.83s-5.67 4.63-5.63 7.36c.04 2.73 1.78 8.33 1.78 8.33s.55-6.2 1.33-8.1a66.73 66.73 0 0 1 4.2-6.9zM166.71 172.69s-1.41 4.35-.74 6 3.85 6.7 3.85 6.7l2.35-.24s-4.31-5.93-4.58-7.2-.88-5.26-.88-5.26Z"
      />
      <path fill="#2d1e45" d="M153.48 149.33c.1-.18-2.16 4-1.77 4.28a45.26 45.26 0 0 0 4.7 1.3l2.45-4.12z" />
      <path
        fill="#a8a8a8"
        d="M199.45 125.51s-2.73 16.46-2.06 19.23c.67 2.77 4.83 10.85 4.83 10.85l-.17 6s-6.67-9.3-7.1-14.37c-.43-5.06 4.5-21.7 4.5-21.7zM185.18 161.58s.37-9.31.71-11.86 2.48-10.54 2.48-10.54l-7.72 6.91-1.75 18.61Z"
      />
    </g>
  </svg>
);
